export const environment = {
  production: false,
  domain: 'localhost:4200',
  region: 'ap-northeast-1',
  api_base_url: 'https://test.apisec.online-pci.net/v1/',
  chime_url: 'https://test.apisec.online-pci.net/v1/chime/',
  userPoolIdForPharmacy: 'ap-northeast-1_uhohxJ5oJ',
  clientIdForPharmacy: '7kvjf676bmaupq1en6ad2t9ro1',
  userPoolIdForPharmacist: 'ap-northeast-1_FZlfTcquo',
  clientIdForPharmacist: '7r657oq03r0qqcs882m1maf7a7',
  title: 'ASTEMTest環境',
  windowTitle: 'Connect Online (ASTEM test)',
  firebase: {
    apiKey: 'AIzaSyBmvOHWh4VLBbZhR33vbS4DBezAavHLMn8',
    authDomain: 'connect-online-3520a.firebaseapp.com',
    projectId: 'connect-online-3520a',
    storageBucket: 'connect-online-3520a.appspot.com',
    messagingSenderId: '585045901396',
    appId: '1:585045901396:web:2d003a5631d47ddf462dc1',
    measurementId: 'G-LV945C3Z1X',
  },
};
